import React, { useState, useRef } from "react"
import Footer from "src/components/Footer"
import Layout from "src/components/layout"
import SEO from "src/components/seo"
import CartoonsList from "src/components/cartoons/CartoonsList"
import Breadcrumb from "src/components/Breadcrumb"

import { Container, Col, Row } from "react-bootstrap"
import { graphql } from "gatsby"

import "src/assets/less/cartoons.less"

const Cartoons = ({ data }) => {
  const [cartoonPos, setCartoonPos] = useState([])

  const metadataTitle = data.wpPage.metadata.metadataTitle ? data.wpPage.metadata.metadataTitle : "Adlai Abdelrazaq"
  const metadataDescription = data.wpPage.metadata.metadataDescription ? data.wpPage.metadata.metadataDescription : "I am a deveoloper"
  const metadataImg = data.wpPage.metadata.metadataImage ? data.wpPage.metadata.metadataImage.localFile.url : ''

  const sortedCartoons = data.allWpCartoon.edges.sort((a, b) => {
    return new Date(b.node.date) - new Date(a.node.date);
  });

  return (
    <Layout page="cartoons">
      <SEO title={metadataTitle}
          description={metadataDescription}
          imageURL={metadataImg} />
      <Container>
        <Row>
          <Col xs={12}>
            <Breadcrumb />
            <div className={`border-bottom`} dangerouslySetInnerHTML={{__html: data.wpPage.content}}></div>
            <CartoonsList cartoons={sortedCartoons} 
                          cartoonPos={cartoonPos}
                          setCartoonPos={setCartoonPos}
                          toggleModal={() => {}} />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Layout>
  )
}

export default Cartoons


//cG9zdDo4Nw== is the id for the homepage... at least on dev..
export const pageQuery = graphql`
  query MyQuery {
    allWpCartoon {
      edges {
        node {
          title
          slug
          cartoon {
            imagePng {
              srcSet
              sourceUrl
              mediaDetails {
                height
                width
              }
            }
            background {
              sourceUrl
            }
          }
          date
        }
      }
    }
    wpPage(title: {eq: "CARTOONS"}) {
      metadata {
        metadataTitle
        metadataImage {
          localFile {
            url
          }
        }
        metadataDescription
      }
      title
      content
    }
  }`